import { Component, OnInit } from '@angular/core';
import { setTheme } from 'ngx-bootstrap/utils';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UserService } from './services/user.service'
import { ConfirmedValidator } from './services/confirm-validator';
import { UserSessionService } from './sevices/user-session.service'

import { User } from './models/index'
import { Error } from './models/index'
import { Router } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'ng-greenline';
  loginForm: UntypedFormGroup;
  signupForm: UntypedFormGroup;
  resetForm: UntypedFormGroup;
  codeForm: UntypedFormGroup;
  passwordUpdateForm:  UntypedFormGroup;
  resMsg:any;
  res:any;
  resLogin:any;
  isLoggedIn:any;
  user:User;
  api_error:Error;
  successLogin:boolean=false;
  loadingStart:boolean = false;
  loadingSignup:boolean = false;
  loadingLogin:boolean = false;
  loginsubmitted=false;
  submittedsignup=false
  loginsubmittedstatus=false;
  api_error_login:any;
  isHidden:boolean = true;
  is_api_error_login:boolean = false;

  submitted=false;
  submittedsignupstatus=false
  success=false
// reset password vars
  resetsubmitted:boolean = false;
  loadingReset:boolean = false;
  successReset:boolean = false;
  resetSuccessMsg:any;
  user_email_reset:string;
  resetErrMsg:string;
// submit reset code variables
  codeubmitted:boolean=false;
  loadingCode:boolean=false;
  successode:boolean = true;
  codeSuccessMsg:string;
  passToken:string;

  // update password variables
  updateSubmitted:boolean = false;
  loadingPasswordUpdate:boolean = false;
  loadingUpdatePassword:boolean = false;
  successPasswordUpdate:boolean = false;
  updatePassSuccessMsg: string;


  constructor( private _fb: UntypedFormBuilder,
    private sessionService:UserSessionService,
    private router: Router,
    private users:UserService) {

    setTheme('bs4'); // or 'bs4'
    this.loginForm = _fb.group({
      email:['', [Validators.required,Validators.email]],
      password:['', Validators.required],
    });

    this.signupForm = _fb.group({
      first_name:['', Validators.required],
      last_name:['', Validators.required],
      phone_number:['', [Validators.required,Validators.pattern('^(?:254|\\+254|0)(7|1)[0-9]{8}$')]],
      id_number:['',[ Validators.required,Validators.pattern('^[a-zA-Z0-9]*$'),Validators.minLength(7),Validators.maxLength(14)]],
      email:['', [Validators.required,Validators.email]],
      password:['', [Validators.required,Validators.minLength(6)]],
    });

    this.resetForm = _fb.group({
      email:['', [Validators.required,Validators.email]],
    });

    this.codeForm = _fb.group({
      code:['', Validators.required],
    });

    this.passwordUpdateForm = _fb.group({
      password:['', [Validators.required,Validators.minLength(8)]],
      confirmpassword:['', [Validators.required,Validators.minLength(8)]],
    },{ 
      validator: ConfirmedValidator('password', 'confirmpassword')
    });

  }

  ngOnInit() {

    this.isLoggedIn = sessionStorage.getItem('isLoggedInEasy');
    if(this.isLoggedIn ){
      this.user=new User().deserialize(JSON.parse(sessionStorage.getItem('user')))
      // this.getUserProfile(this.user);
    }else{
      this.isLoggedIn=false
    }
  }

  get f() { return this.signupForm.controls; }
  logoutUser(){
    sessionStorage.clear();
    this.isLoggedIn=false;
  }
  getUserProfile(user:any){
    this.users.getProfile(user).subscribe(
      response => {
          this.loadingStart=false;
          this.resMsg=response;
          sessionStorage.setItem('userEasy',JSON.stringify(this.resMsg.data));
      },
      error => {
        sessionStorage.clear();
        this.isLoggedIn=false;
        this.sessionService.changeMessage("nouser")
      }
    );
  }

  onActivate(event) {
    let scrollToTop = window.setInterval(() => {
        let pos = window.pageYOffset;
        if (pos > 0) {
            window.scrollTo(0, pos - 20); // how far to scroll on each step
        } else {
            window.clearInterval(scrollToTop);
        }
    }, 16);
}

initiateResetPassword= () => {
  document.getElementById("closeLogin").click();
  document.getElementById("openModalReset").click();
}

submitLogin = () => {
  this.loginsubmitted=true;
  this.loginsubmittedstatus=true;
  this.loadingLogin=true;

 if (this.loginForm.invalid ) {

  setTimeout(function () {
    this.loginsubmittedstatus=false;
  },2000)
  this.loginsubmitted=false;
  this.loadingLogin=false;
    return;
 }
let value = this.loginForm.value;
let webformlogin={
  "password": value.password,
  'email':value.email,
}
this.users.login(webformlogin).subscribe(
  response => {
      this.loadingLogin=false;
      this.loginsubmitted=false;
      this.successLogin = true;
      this.resMsg=response;
      if(this.resMsg.data){
        sessionStorage.setItem('isLoggedInEasy','true');
        sessionStorage.setItem('userEasy',JSON.stringify(this.resMsg.data));
        this.user=new User().deserialize(JSON.parse(sessionStorage.getItem('userEasy')))
        this.isLoggedIn=true;
        this.sessionService.changeMessage(this.resMsg.data)
        sessionStorage.setItem('userEasy',JSON.stringify(this.resMsg.data));
        document.getElementById("closeLogin").click();
        
        this.router.navigate(['/user-profile'])
        
      }
  },
  error => {
      this.successLogin = false;
      this.loginsubmitted=false;
      this.loadingLogin=false;
      this.is_api_error_login=true;
      this.api_error=new Error().deserialize(error.error.errors[0])
      this.api_error_login=error.error.errors[0].error;
      console.log(error.error.errors[0].error)
  }
);
}

submitSignUp = () => {
  this.submittedsignup = true;
  this.submittedsignupstatus = true;
  this.loadingSignup=true;
  // stop here if form is invalid
  if (this.signupForm.invalid ) {
    setTimeout(function () {
      this.submittedsignupstatus=false;
    },2000)
    this.submittedsignup = false;
    this.loadingSignup=false;
      return;
  }
  let value = this.signupForm.value;
  let webformdata={
    "first_name": value.first_name,
    "last_name": value.last_name,
    "email": value.email,
    "password": value.password,
    "id_number": value.id_number,
    "phone_number": '+254'+ value.phone_number.substring(1),
    "channel": "greenline"
  }
  this.users.signUp(webformdata).subscribe(
    response => {
      this.loadingSignup=false;
        this.success = true;
        this.resMsg=response;
        this.res= this.resMsg.data.message;
        document.getElementById("closeSignup").click();
        document.getElementById("openSuccessButton").click();
    },
    error => {
        this.success = false;
        this.loadingSignup=false;
        this.user=new User().deserialize(error.error.errors[0])
        console.log(this.user);
    }
  );
}

submitResetPassword = () =>{
  this.resetsubmitted=true;
  this.loadingReset=true;
 if (this.resetForm.invalid ) {
  this.resetsubmitted=false;
  this.loadingReset=false;
    return;
 }
let value = this.resetForm.value;
let webformReset={
  "email": value.email
}
this.users.passwordReset(webformReset).subscribe(
  response => {
      this.loadingReset=false;
      this.resetsubmitted=false;
      this.successReset = true;
      this.resMsg=response;
      this.resetSuccessMsg=this.resMsg.data.message;
      this.user_email_reset=value.email;
      document.getElementById("openCodeButton").click();
      document.getElementById("closeReset").click();
  },
  error => {
      this.successReset = false;
      this.resetsubmitted=false;
      this.loadingReset=false;
      this.api_error= new Error().deserialize(error.error.errors);
      this.resetErrMsg=this.api_error.getTheMessage(this.api_error);
      console.log(this.resetErrMsg)
  }
);
}

// send reset code to verify
submitCode= () =>{
  this.codeubmitted=true;
  this.loadingCode=true;
 if (this.codeForm.invalid ) {
  this.codeubmitted=false;
  this.loadingCode=false;
    return;
 }
let value = this.codeForm.value;

let webformCode={
  "email": this.user_email_reset,
  "otp": value.code
}

this.users.sendCode(webformCode).subscribe(
  response => {
      this.loadingCode=false;
      this.codeubmitted=false;
      this.successode = true;
      this.resMsg=response;
      this.codeSuccessMsg=this.resMsg.data.message;
      this.passToken=this.resMsg.data.token;
      document.getElementById("closeCode").click();
      document.getElementById("openUpdatePassword").click();
  },
  error => {
      this.successode = false;
      this.codeubmitted=false;
      this.loadingCode=false;
      this.api_error= new Error().deserialize(error.error.errors);
      this.codeSuccessMsg=this.api_error.getTheMessage(this.api_error);
  }
);
}

// send reset code to verify
updatePassword= () =>{
  this.updateSubmitted=true;
  this.loadingPasswordUpdate=true;
 if (this.passwordUpdateForm.invalid ) {
    this.updateSubmitted=false;
    this.loadingPasswordUpdate=false;
    return;
 }
let value = this.passwordUpdateForm.value;

let webformCode={
  "email": this.user_email_reset,
  "new_password": value.password,
  "token": this.passToken
}
this.users.sendUpdatePassword(webformCode).subscribe(
  response => {
      this.loadingUpdatePassword=false;
      this.updateSubmitted=false;
      this.successPasswordUpdate = true;
      this.resMsg=response;
      this.updatePassSuccessMsg=this.resMsg.data.message;
    
      setTimeout(function () {
        document.getElementById("closePasswordUpdate").click();
        document.getElementById("openResetSuccessButton").click();
      },2000)


  },
  error => {
      this.successPasswordUpdate = false;
      this.updateSubmitted=false;
      this.loadingPasswordUpdate=false;
      this.api_error= new Error().deserialize(error.error.errors);
      this.updatePassSuccessMsg=this.api_error.getTheMessage(this.api_error);
  }
);
}

}
