import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {BookingService,HomeService, DataService } from '../services/index'
import {Results } from '../models/index'
import { Renderer2 } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { formatDate, LocationStrategy  } from '@angular/common';
import { Base64 } from 'js-base64';

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.css']
})
export class BookingComponent implements OnInit {
  reactiveForm: UntypedFormGroup;reactiveMobileForm: UntypedFormGroup;

  public pickupplaceholder: string = 'Boarding Point';
  public dropoffplaceholder: string = 'Dropoff Point';
  public keyword = 'name';
  checkiftoday:boolean;
  public pickplaceholder: string = 'Enter Pick Up';
  public destplaceholder: string = 'Enter Drop Off';
  public historyHeading: string = 'Recently selected';
  today=new Date();
  bsValue = new Date();
  destinationLoading=false;
  beforeyesterday:Date =( d => new Date(d.setDate(d.getDate()-2)) )(new Date);
  yesterday:Date = ( d => new Date(d.setDate(d.getDate()-1)) )(new Date);
  tomorrow:Date = ( d => new Date(d.setDate(d.getDate()+1)) )(new Date);
  aftertomorrow:Date = ( d => new Date(d.setDate(d.getDate()+2)) )(new Date);

  //traveldate:any;
  data = [];formdata={};
  formdataTravel={};
  formdataReturn={};
  private sub: any;
  res: any = {};
  schedule: any ={};
  routename: any ={};
  pickup:any =[];
  dropoff:any =[];
  seatsSelected: any=[];
  fleet: any ={};
  seat_price: any ={};
  params:any;
  bookingdetails: any;
  results: any ;
  resultsholder: any=[] ;
  isResultsLoading=false;
  showSeats=false;
  changeI=false;
  pickUpInit: string;
  dropOffInit: string;
  travelDateInit: string;
  isUnavailabe=false;
  dataLoading=false;
  isReturnTicket=false;
  isShowingFilter=false;
  totalFare=0;
  returning=false;
  submitted:true;
  selectedIndex = -1;
  selectedDepatureId:string;
  selectedDestinationId:string;
  fromPlaceholder="From";
  searchDate:string;
  toPlaceholder="To";
  traveldate =new Date();
  allSeatArray=[];
  bookedSeatArray=[];
  constructor(
    private render:Renderer2,
    private router: Router,
    private route: ActivatedRoute,
    private service:BookingService,
    private homeservice:HomeService,
    private dataService:DataService,
    private locationStrategy: LocationStrategy,
    private _fb: UntypedFormBuilder
  ) {
    this.reactiveForm = _fb.group({
      pickup:['', Validators.required],
      dropoff:['', Validators.required],
      traveldate:[new Date(), Validators.required],
      returndate:[]
    });
    this.reactiveMobileForm = _fb.group({
      pickup:['', Validators.required],
      dropoff:['', Validators.required],
      traveldate:[new Date(), Validators.required],
      returndate:[]
    });

   }

   returnClicked = () =>{
    this.returning=true;
  }

  closeReturn = () =>{
    this.returning=false;
  }

  showFilter () {
    this.isShowingFilter=true;
  }
  hideFilter() {
    this.isShowingFilter=false;

  }

  ngOnInit() {

    this.loadDestinations();
    this.dataService.currentMessage.subscribe(message =>{
      if(!message){
      message=localStorage.getItem('search-greenline');
      if(!message){
        this.router.navigate(['/index.html'])
      }
    }

     localStorage.setItem('search-greenline', message);
     
      this.bookingdetails = JSON.parse(Base64.decode(message))
      this.traveldate = new Date(formatDate(this.bookingdetails.traveldate,"yyyy-MM-dd","en-KE"));
      this.pickUpInit=this.bookingdetails.pickupname;
      this.dropOffInit=this.bookingdetails.dropoffname;
      this.travelDateInit=this.bookingdetails.traveldate;
      this.fromPlaceholder = this.bookingdetails.pickupname;
      this.toPlaceholder = this.bookingdetails.dropoffname;
      this.searchDate = formatDate(this.travelDateInit,"yyyy-MM-dd","en-KE");
      this.checkDates();
      this.searchBuses(JSON.parse(Base64.decode(message)))
    });

  }



  checkDates=() =>{
    let traveldate = formatDate(this.travelDateInit,"yyyy-MM-dd","en-KE");
    let today = formatDate(this.today,"yyyy-MM-dd","en-KE");
    var date = new Date(traveldate);
    this.beforeyesterday=new Date(date.setDate(date.getDate()-2));
    this.yesterday=new Date(date.setDate(date.getDate()+1));
    this.tomorrow =new Date(date.setDate(date.getDate()+2))
    this.aftertomorrow =new Date(date.setDate(date.getDate()+2));
      if(traveldate == today)
          return true;
      else
        return false;
  }
  changeDate=(selected) =>{

    this.travelDateInit=formatDate(selected,"yyyy-MM-dd","en-KE");
    this.checkDates();
    const dropOff = this.data.find(city => city.text === this.pickUpInit);
    const pickUp = this.data.find(city => city.text === this.dropOffInit);
    let webformdata={
      'pickup' : dropOff.id,
      'dropoff' : pickUp.id,
      'pickupname' : this.pickUpInit,
      'dropoffname' :this.dropOffInit,
      'traveldate' :this.travelDateInit,
    }
    let message =Base64.encode(JSON.stringify(webformdata));
    localStorage.setItem('search-greenline', message);

    this.searchBuses(webformdata);
  }

  interChange=()=>{
    this.changeI=!this.changeI;
    if(this.changeI){

      this.pickUpInit=this.bookingdetails.dropoffname;
      this.dropOffInit=this.bookingdetails.pickupname;
      this.travelDateInit=this.bookingdetails.traveldate;

       const dropOff = this.data.find(city => city.text === this.pickUpInit);
       const pickUp = this.data.find(city => city.text === this.dropOffInit);

      let webformdata={
        'pickup' : dropOff.id,
        'dropoff' : pickUp.id,
        'pickupname' : this.pickUpInit,
        'dropoffname' :this.dropOffInit,
        'traveldate' :this.travelDateInit,
      }

      this.searchBuses(webformdata);
    }else{
      this.pickUpInit=this.bookingdetails.pickupname;
      this.dropOffInit=this.bookingdetails.dropoffname;
      this.travelDateInit=this.bookingdetails.traveldate;

       const dropOff = this.data.find(city => city.text === this.bookingdetails.dropoffname);
       const pickUp = this.data.find(city => city.text === this.bookingdetails.pickupname);

      let webformdata={
        'pickup' : pickUp.id,
        'dropoff' : dropOff.id,
        'pickupname' : this.pickUpInit,
        'dropoffname' :this.dropOffInit,
        'traveldate' :this.travelDateInit,
      }

      this.searchBuses(webformdata);

    }
  }

  private loadDestinations= () => {
    this.homeservice.getDestinations().subscribe(
      response => {
        this.res=response;
        let cities=[];
        this.res.cities.forEach(function (city) {
         if(!city.hide_from_api){
         let  ncity = {
           id:city.id,
           text:city.name,
           hide_from_api:city.hide_from_api
           }
           cities.push(ncity);
         }
       });
        this.data = cities;
      },
      error => {
        // console.log('oops', error);
      }
    );
  }

  interChangeValues(pickup,dropoff){
    this.fromPlaceholder = pickup;
    this.toPlaceholder = dropoff;
  }

  forMatThisData = (data) => {
    return formatDate(data,"dddd dS, M","en-KE");
  }

  submitWebForm(reactiveForm) {
    this.submitted = true;
    let value = reactiveForm;
    var traveldate = formatDate(value.traveldate,"yyyy-MM-dd","en-KE");
    value.traveldate=traveldate;
    const fpickup:any = this.data.filter(item => item.id === value.pickup);
    const fdropoff:any = this.data.filter(item => item.id === value.dropoff);

    let webformdata={
      'pickup' : value.pickup,
      'dropoff' : value.dropoff,
      'pickupname' : fpickup[0].text,
      'dropoffname' :fdropoff[0].text,
      'traveldate' : traveldate,
    }

    let message =Base64.encode(JSON.stringify(webformdata));
    localStorage.setItem('search-greenline', message);

    this.searchBuses(webformdata);
  }

  submitMobileForm() {
    this.submitted = true;
     let value = this.reactiveMobileForm.value;

    var traveldate = formatDate(value.traveldate,"yyyy-MM-dd","en-KE");
    value.traveldate=traveldate;


    let mobileformdata={
      'pickup' : value.pickup.id,
      'dropoff' : value.dropoff.id,
      'pickupname' : value.pickup.name,
      'dropoffname' : value.dropoff.name,
      'traveldate' : traveldate,
    }

    let message =Base64.encode(JSON.stringify(mobileformdata));
    localStorage.setItem('search-greenline', message);

    this.searchBuses(mobileformdata);
  }

  toggleShow(results: any) {
    this.seatsSelected=[];
    this.totalFare=0;
      results.hide_from_ui = ! results.hide_from_ui;
    }

    selectSeats(results: any) {

    this.bookedSeatArray = results.fleet.booked_seats.replaceAll(' ','').split(",");
    this.allSeatArray= results.fleet.seat_numbers.concat(this.bookedSeatArray)

      this.seatsSelected=[];
      this.totalFare=0;
      results.hide_from_ui = true;
      this.refreashSeats();
      }
     seatSelect(event,result:any,id:any){
      if(event.srcElement.parentElement.classList.contains('unavailable') || event.srcElement.parentElement.classList.contains('no-single-seat')){
      }else{
        if(this.seatsSelected.includes(id)){
          if(event.srcElement.classList.contains('single-seat')){
            event.srcElement.classList.remove("selected");
          }
          this.totalFare=this.totalFare-parseInt(result.seat_price.normal);
          this.seatsSelected = this.seatsSelected.filter(item => item !== id);
          event.srcElement.parentElement.classList.remove('selected');
        }else{
          if(event.srcElement.classList.contains('single-seat')){
            event.srcElement.classList.add("selected");
          }
          this.totalFare=this.totalFare+parseInt(result.seat_price.normal);
          this.seatsSelected.push(id);
          event.srcElement.parentElement.classList.add('selected');
        }
      }
     }



     refreashSeats(){
      this.dataLoading=false;

      this.service.onSearch(this.bookingdetails).subscribe(
        response => {
          this.dataLoading=false;
          this.res = response;
          this.schedule =  this.results.schedule;
          this.routename =  this.results.route;
          this.pickup =  this.results.pickup;
          this.dropoff =  this.results.dropoff;
          this.fleet =  this.results.fleet;
          this.seat_price =  this.results.seat_price;
        },
        error => {
          // console.log('oops', error);
        }
      );

    }

    submitForm(result) {
      if(this.isReturnTicket){
        this.formdataReturn={
          'pickup' : this.pickUpInit,
          'dropoff' : this.dropOffInit,
          'traveldate': this.travelDateInit,
          'totalfare' : this.totalFare,
          'seatsSelected' : this.seatsSelected,
          'schedule':result.schedule,
          "trip_id": result.trip_id,
          "search_details": this.bookingdetails,
          "route_id": result.route.id,
          "fleet_registration_id": result.fleet.fleet_registration_id,
          "fleet_type_id": result.fleet.fleet_type_id,
        }
      }else{
        this.formdataTravel={
          'pickup' : this.pickUpInit,
          'dropoff' : this.dropOffInit,
          'traveldate': this.travelDateInit,
          'totalfare' : this.totalFare,
          'seatsSelected' : this.seatsSelected,
          'schedule':result.schedule,
          "trip_id": result.trip_id,
          "search_details": this.bookingdetails,
          "route_id": result.route.id,
          "fleet_registration_id": result.fleet.fleet_registration_id,
          "fleet_type_id": result.fleet.fleet_type_id,
        }
      }
      if(this.bookingdetails.returning && !this.isReturnTicket){
        let returnsearch={
          'pickup':this.bookingdetails.dropoff,
          'dropoff':this.bookingdetails.pickup,
          'traveldate':this.bookingdetails.returndate,
        }
        this.isReturnTicket=true;
        this.searchBuses(returnsearch);
      } else if(this.bookingdetails.returning && this.isReturnTicket){
        let paytravel=Base64.encode(JSON.stringify(this.formdataTravel));
        let payreturn=Base64.encode(JSON.stringify(this.formdataReturn));
        let syncData={
            'travel':paytravel,
            'return':payreturn,
            'returning':true
        }
        let pay=Base64.encode(JSON.stringify(syncData));
        let paymessage=Base64.encode("Opps sory mate!");
        this.dataService.changeMessage(pay);
        this.router.navigate(['/payments', paymessage])

      } else {
        let paytravel=Base64.encode(JSON.stringify(this.formdataTravel));
          let syncData={
            'travel':paytravel,
            'return':'',
            'returning':false
          }
        let pay=Base64.encode(JSON.stringify(syncData));
        let paymessage=Base64.encode("Opps sory mate!");
        this.dataService.changeMessage(pay);
        this.router.navigate(['/payments', paymessage])
      }
    }

  searchBuses(search){
    this.isResultsLoading=true;
    this.destinationLoading=true;
    this.travelDateInit=search.traveldate;
    this.pickUpInit = search.pickupname;
    this.dropOffInit = search.dropoffname;
    this.pickup =   parseInt(search.pickup)
    this.dropoff =  parseInt(search.dropoff);
    this.interChangeValues(search.pickupname,search.dropoffname);
    this.service.onSearch(search).subscribe(
      response => {
        this.isResultsLoading=false;
        this.destinationLoading=false;
        this.res = response;

        let trips=[];
         this.res.trip_list.forEach(function (trip) {
          if(!trip.hide_from_api){
            trips.push(trip);
          }
        });
        this.results = trips;

        if( this.res.trip_list ) {
          this.resultsholder=trips;
          // this.pickup =   search.pickup;
          // this.dropoff =  search.dropoff;

        }

      },
      error => {
        // console.log('oops', error);
      }
    );

  }


  selectEvent(item) {
    // do something with selected item
  }

  onChangeSearch(val: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  onFocused(e){
    // do something when input is focused
  }


}
