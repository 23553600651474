const homeUrl= 'https://dashboard.greenlinesafaris.com/api/v1/';
const marketplaceUrl= 'https://marketplace-v2.buupass.com/marketplace/';
const homeUrlTrips= 'https://dashboard.greenlinesafaris.com/';
  const v2P='https://pay-v2.buupass.com/payments/';
  const usersApi='https://v2-user-management.buupass.com/user-management/users/';
const usersApiLogin='https://v2-user-management.buupass.com/user-management/users/login';
export const environment = {
    api_key:'da581d81d43605638ecae3d00393133400e535b8',
    api_marketplace_key:'66Shf2aP.owT6xw6QKSWNO9EeQIIlz20JM3nNHul3',
    production: false,
    getDestinations: homeUrl+'website/api_destinations/',
    bookingCheckPayApi: marketplaceUrl + 'booking/pay',
    searchBuses: homeUrl+'website/api_search',
    bookingApi: homeUrl+'website/api_booking/',
    bookingApiV2: homeUrl+'website/api_booking_v2/',
    confirmApi:homeUrl+'website/api_mpesa_query_status/',
    v2confirm: v2P+'c2b/transaction/status/',
    retrieveEmail: usersApi+'retrieve/',
    resetPassword: usersApi+'password-reset',
    codePass: usersApi + 'code-validate',
    updateCodePass: usersApi + 'password-reset/',
    signUp: usersApi+'registration',
    loginUser:usersApi+'login',
    getProfile: usersApi+'profile',
    usersApiLogin:usersApiLogin,
    getTrips: homeUrl+'booking/search?search=',
    getHistory: homeUrlTrips+'api/history/'
    
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
