import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertTime'
})
export class ConvertTimePipe implements PipeTransform {

  transform(time: string): string {
    let hours24 = parseInt(time.slice(0, 2)); // 24-hour format hours
    let minutes = time.slice(3, 5);
    
    let ampm = hours24 >= 12 ? 'PM' : 'AM';
    let hours12 = hours24 % 12; // 12-hour format hours
    hours12 = hours12 === 0 ? 12 : hours12; // Adjust for 12 AM and 12 PM

    let dayOrNight = "";

    // Use 24-hour format for determining day or night
    if (hours24 >= 6 && hours24 < 18) {
      dayOrNight = 'Day'; // Day time (from 6am to 6pm)
    } else {
      dayOrNight = 'Night'; // Night time (from 6pm to 6am)
    }
    
    let strTime = hours12 + ':' + minutes + ' ' + ampm + ' (' + dayOrNight+')';
    return strTime;
  }
}
