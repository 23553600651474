<app-headerhome></app-headerhome>
<div class="progress-cntr-wrpr">
        <div class="progress-cntr">
            <div class="progress-tracker-cntr transparent">
                <div class="progress-tracker">
                    <div class="progress-item passed ">
                        <div class="item-wrapper">
                            <div class="progess-item-label">Booking  </div>
                        </div>
                    </div>
                    <div class="progress-item passed">
                        <div class="item-wrapper">
                            <div class="progess-item-label">Payment</div>
                        </div>
                    </div>
                    <div class="progress-item last passed">
                        <div class="item-wrapper">
                            <div class="progess-item-label">Ticket</div>
                        </div>
                    </div>
                    <div class="progress-tracker-completed" style="width: 0%;"></div>
                </div>
            </div>
        </div>
    </div>
<div class="theme-page-section  theme-page-section-gray">
        <div class="container">
          <div class="row">
            <div class="col-md-5 offset-md-3">
              <div class="theme-payment-success">
                <div class="theme-payment-success-header">
                  <i class="fa fa-check-circle theme-payment-success-header-icon"></i>
                  <h1 class="theme-payment-success-title">Booking Successful</h1>
                  <p class="theme-payment-success-subtitle">Thank You. Your Booking Order is Confirmed Now.
                        A confirmation SMS has been sent to your provided phone number.</p>
                        <!-- <p><qrcode [qrdata]="'{{ticketDetails.booking_id}}'" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
                        </p> -->
                </div>
                <div class="theme-payment-success-box">
                  <ul class="theme-payment-success-summary">
                    <li>BookingID
                      <span>{{ticketDetails.booking_id}}</span>
                    </li>
                    <li>Travel Date
                      <span>{{ticketDetails.travel_date}}</span>
                    </li>
                    <li>Pickup
                      <span>{{ticketDetails.pickup_location}}</span>
                    </li>
                    <li>Dropoff
                      <span>{{ticketDetails.drop_off_location}}</span>
                    </li>
                    <li>Seats
                      <span>{{ticketDetails.booked_seats}}</span>
                    </li>
                    <li >Passenger

                          <span >
                              <div *ngFor="let passenger of ticketDetails.passengers">
                            {{passenger.name}} seat <b>{{passenger.seat_number}}</b>
                            <!-- <a href="#"
                            class=""
                            data-toggle="modal"
                            data-target="#QRopupForm{{passenger.pnr_number}}"><small> View QR code</small></a> -->
                                <!-- end modal-shared -->
    <div class="modal-popup">
      <div
        class="modal fade"
        id="QRopupForm{{passenger.pnr_number}}"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                id="closeLogin"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" class="la la-close"></span>
              </button>
            </div>
            <div class="modal-body">
              <div class="contact-form-action">
                
                    <div
                      class="d-flex align-items-center justify-content-between"
                    >
                      <p class="forgot-password">
                        <qrcode [qrdata]="'{{ticketDetails.booking_id}}'" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
                      </p>
                    </div>
                  </div>
              </div>
              <!-- end contact-form-action -->
            </div>
          </div>
        </div>
      </div>
    <!-- end modal-popup -->
                              </div>
                          </span>
                      </li>
                  </ul>
                  <p class="theme-payment-success-check-order">A confirmation Email and SMS has been sent to your provided email address and phone.

                  </p>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>


<app-footer></app-footer>
