import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PaymentsComponent } from './payments/payments.component';
import { HomeComponent } from './home/home.component';
import { BookingComponent } from './booking/booking.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { SuccessComponent } from './success/success.component';
import { ContactusComponent } from './contactus/contactus.component';
import { CarhireComponent } from './carhire/carhire.component';
import { AboutusComponent } from './aboutus/aboutus.component';

import { UserProfileComponent } from './user-profile/user-profile.component';
import { MyBookingComponent } from './my-booking/my-booking.component';
import { CheckPaymentComponent } from './check-payment/check-payment.component';
import { ProcessRetryComponent } from './process-retry/process-retry.component';
import { RetrySuccessfulComponent } from './retry-successful/retry-successful.component';
const routes: Routes = [
  { path: '', redirectTo: '/', pathMatch: 'full' },
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'booking/:id',      component: BookingComponent },
  { path: 'payments/:id',      component: PaymentsComponent },
  { path: 'pay/:id',      component: CheckPaymentComponent, data: {animation: 'Payments'}, },
  { path: 'process/pay/:id',      component: ProcessRetryComponent, data: {animation: 'Payments'}, },
  { path: 'retry/successful/:id',      component: RetrySuccessfulComponent, data: {animation: 'Payments'}, },
  { path: 'booking-successful/:id',      component: SuccessComponent },
  { path: 'confirm-payments/:id',      component: ConfirmComponent },
  { path: 'about-us',      component: AboutusComponent },
  { path: 'contact-us',      component: ContactusComponent },
  { path: 'car-hire',      component: CarhireComponent },
  { path: 'user-profile',      component: UserProfileComponent },
  { path: 'my-booking',      component: MyBookingComponent },
  
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
