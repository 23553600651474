import { Component } from '@angular/core';

@Component({
  selector: 'app-carhire',
  templateUrl: './carhire.component.html',
  styleUrls: ['./carhire.component.css']
})
export class CarhireComponent {

}
