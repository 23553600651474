import { Component, OnInit } from '@angular/core';
import { formatDate, PlatformLocation  } from '@angular/common';
import {Router} from "@angular/router"
import { Base64 } from 'js-base64';
import {HomeService,DataService } from '../../services/index'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(
    private router:Router,
    private dataService : DataService,
  ) { }

  ngOnInit() {
  }


  clickNairobiKTL(){
    var traveldate = formatDate(new Date(),"yyyy-MM-dd","en-KE");

    let formdata={
      dropoff: "9",
      dropoffname: "Kitale",
      pickup: "4",
      pickupname: "Nairobi",
      traveldate : traveldate,
    }

    let p=Base64.encode(JSON.stringify(formdata));
    this.dataService.changeMessage(p);
    this.router.navigate(['/booking', traveldate])


  }

  clickNairobiMl(){
    var traveldate = formatDate(new Date(),"yyyy-MM-dd","en-KE");

    let formdata={
      'dropoff': "80",
      'dropoffname': "Malaba",
      'pickup': "4",
      'pickupname': "Nairobi",
      'traveldate' : traveldate,
    }

    let p=Base64.encode(JSON.stringify(formdata));
    this.dataService.changeMessage(p);
    this.router.navigate(['/booking', traveldate])

  }

  clickNairobiLk(){
    var traveldate = formatDate(new Date(),"yyyy-MM-dd","en-KE");

    let formdata={
      'dropoff': "72",
      'dropoffname': "Lwakhakha",
      'pickup': "4",
      'pickupname': "Nairobi",
      'traveldate' : traveldate,
    }

    let p=Base64.encode(JSON.stringify(formdata));
    this.dataService.changeMessage(p);
    this.router.navigate(['/booking', traveldate])
    
  }

  clickNairobiBg(){
    var traveldate = formatDate(new Date(),"yyyy-MM-dd","en-KE");

    let formdata={
      'dropoff': "72",
      'dropoffname': "Bungoma",
      'pickup': "4",
      'pickupname': "Nairobi",
      'traveldate' : traveldate,
    }

    let p=Base64.encode(JSON.stringify(formdata));
    this.dataService.changeMessage(p);
    this.router.navigate(['/booking', traveldate])
    
  }

  clickNairobiMu(){
    var traveldate = formatDate(new Date(),"yyyy-MM-dd","en-KE");

    let formdata={
      'dropoff': "72",
      'dropoffname': "Mumias",
      'pickup': "4",
      'pickupname': "Nairobi",
      'traveldate' : traveldate,
    }

    let p=Base64.encode(JSON.stringify(formdata));
    this.dataService.changeMessage(p);
    this.router.navigate(['/booking', traveldate])
    
  }

  
  

  clickNairobiMb(){
    var traveldate = formatDate(new Date(),"yyyy-MM-dd","en-KE");

    let formdata={
      'dropoff': "89",
      'dropoffname': "Mbale",
      'pickup': "4",
      'pickupname': "Nairobi",
      'traveldate' : traveldate,
    }

    let p=Base64.encode(JSON.stringify(formdata));
    this.dataService.changeMessage(p);
    this.router.navigate(['/booking', traveldate])
    
  }

  

}
