import { Deserializable } from './deserializable.model';

export class User implements Deserializable {

    public first_name: string;
    public last_name:string;
    public email: string;
    public password: string;
    public id_number:string;
    public phone_number: string;
    public token: string;
    public created_at: string;
    deserialize(input: any): this{
        Object.assign(this, input);
        return this;
    }

    getFname() {
      return this.first_name ;
    }
    getLname() {
      return this.last_name;
    }
    getEmail() {
      return this.email;
    }
    getIdno() {
      return this.id_number;
    }
    getPhone() {
      return this.phone_number;
    }
    getToken() {
      return this.token;
    }

    getCreatedAt() {
      return this.created_at;
    }


}
