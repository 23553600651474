import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import  { environment } from '../../environments/environment'
const httpOptions = {
  headers: new HttpHeaders({
   'Authorization':'Token ' + environment.api_key,
  })
};

const httpMarketplaceOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Authorization':  'Api-Key '+ environment.api_marketplace_key
  })
};
@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(
    private http:HttpClient
  ) { }

  submitBooking(booking:any){
    return this.http.post(environment.bookingApi,booking,httpOptions);
  }

  submitBookingReturn(booking:any){
    return this.http.post(environment.bookingApiV2,booking);
  }


  fetchData(query:any){
    return this.http.get(`${environment.bookingCheckPayApi}` +'?m='+ query ,httpMarketplaceOptions);
  }


  submitCheckPay(data:any,id){
    return this.http.post<any>(environment.bookingCheckPayApi +'?m='+ id,data,httpMarketplaceOptions);
  }

}
