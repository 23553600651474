import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmService } from '../services/index'
import { Base64 } from 'js-base64';


@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.css']
})
export class ConfirmComponent implements OnInit {
  sub:any;
  confirmData={};
  requestQuery:any;
  res:any;
  int1:any;
  int2:any;
  int3:any;
  isError:boolean;
  errorMessage:any;
  isWaiting:boolean;
  waitingMessage:any;
  started=0;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service: ConfirmService
  ) { }

  ngOnInit() {

    this.sub = this.route.params.subscribe(params => {
      let data= params['id'];
      this.confirmData=JSON.parse(Base64.decode(data));
      this.confirmMpesa(this.confirmData);
    });
  }
  requestConfirm(){
    this.confirmMpesa(this.confirmData);
  }
  confirmMpesa(dataReceived:any){
    this.service.confirmMpesa(dataReceived.booking_id).subscribe(
      data  => {

        this.res=data;
        var self = this;
        if(this.res.status){

          clearInterval(self.int1);
          clearInterval(self.int2);
          clearInterval(self.int3);

           let f=Base64.encode(JSON.stringify(this.res.data))
           this.router.navigate(['/booking-successful', f])
        }else{

          if(this.res.seat_status == 'reserved'){
            this.isWaiting=true;
            this.waitingMessage=this.res.message;
            var self = this;

            setTimeout(function () {
              if(self.started==0){
                 self.started=1
                 var i = 1000;
                  self.int2 = setInterval(function () {
                  self.confirmMpesa(dataReceived);
                  i += 1000;
                  if (i >= 25000){
                    clearInterval(self.int1);
                    clearInterval(self.int2);
                    clearInterval(self.int3);
                    self.router.navigate(['/']);
                  } ;
                }, 5000);
              }
          }, 1000);
          }else if (this.res.seat_status == 'released'){
            this.isWaiting=false;
            this.isError=true;
            this.errorMessage=this.res.message;
            clearInterval(self.int1);
            clearInterval(self.int2);
            clearInterval(self.int3);
            setTimeout(() => {
              this.router.navigate(['/'])
             }, 3000);
          }else{
            this.isWaiting=false;
            this.isError=true;
            this.errorMessage="Processing your payments please wait.....";
            var self = this;
            setTimeout(function () {
              if(self.started==0){
                self.started=1;
                 var i = 1000;
                 self.int3 = setInterval(function () {
                  self.confirmMpesa(dataReceived);
                  i += 1000;
                  if (i >= 25000){
                    clearInterval(self.int1);
                    clearInterval(self.int2);
                    clearInterval(self.int3);
                    self.router.navigate(['/']);
                  } ;
                }, 5000);
              }
          }, 1000);


          }

        }

      },
      error  => {
        this.isWaiting=false;
          this.isError=true;
          this.errorMessage="Sorry there has been a technical problem.Please try again ";
          var self = this;
          var started=0;
          setTimeout(function () {
            if(self.started==0){
              self.started=1;
               var i = 1000;
                self.int3 = setInterval(function () {
                self.confirmMpesa(dataReceived);
                i += 1000;
                if (i >= 25000){
                  clearInterval(self.int1);
                  clearInterval(self.int2);
                  clearInterval(self.int3);
                  self.router.navigate(['/']);
                } ;
              }, 5000);
            }
        }, 1000);

      }

      )




  }

}
