<div class="about-wrapper">
  <app-headerhome class="header-sec"></app-headerhome>

    <!--start of about us section-->

<!-- ================================
  START BREADCRUMB AREA
================================= -->
<section class="breadcrumb-area bread-bg-9">
  <div class="breadcrumb-wrap">
      <div class="container">
          <div class="row">
              <div class="col-lg-12">
                  <div class="breadcrumb-content">
                      <div class="section-heading">
                          <h2 class="sec__title line-height-50 text-white">Greenline LTD is Your Trusted <br> Bus Company.</h2>
                      </div>
                  </div><!-- end breadcrumb-content -->
              </div><!-- end col-lg-12 -->
          </div><!-- end row -->
      </div><!-- end container -->
  </div><!-- end breadcrumb-wrap -->
  <div class="bread-svg-box">
      <svg class="bread-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 10" preserveAspectRatio="none"><polygon points="100 0 50 10 0 0 0 10 100 10"></polygon></svg>
  </div><!-- end bread-svg -->
</section><!-- end breadcrumb-area -->
<!-- ================================
  END BREADCRUMB AREA
================================= -->

<!-- ================================
  START INFO AREA
================================= -->
<section class="info-area padding-top-100px padding-bottom-70px">
  <div class="container">
      <div class="row">
          <div class="col-lg-4 responsive-column">
              <div class="card-item ">
                  <div class="card-body">
                      <h3 class="card-title mb-2">Our Vision </h3>
                      <p class="card-text">
                        Top Class bus company                      </p>
                  </div>
              </div><!-- end card-item -->
          </div><!-- end col-lg-4 -->
          <div class="col-lg-4 responsive-column">
              <div class="card-item ">
                  <div class="card-body">
                      <h3 class="card-title mb-2">Our Mission</h3>
                      <p class="card-text">
                        Providing good service to our customers.
                      </p>
                  </div>
              </div><!-- end card-item -->
          </div><!-- end col-lg-4 -->
          <div class="col-lg-4 responsive-column">
              <div class="card-item ">
                  <div class="card-body">
                      <h3 class="card-title mb-2">Core-Values </h3>
                      <p class="card-text">
                        Caring, Integrity, Fast Quality Services & Faithful Stewardship.
                      </p>
                  </div>
              </div><!-- end card-item -->
          </div><!-- end col-lg-4 -->
      </div><!-- end row -->
  </div><!-- end container -->
</section><!-- end info-area -->
<!-- ================================
  END INFO AREA
================================= -->

<!-- ================================
  START ABOUT AREA
================================= -->
<section class="about-area padding-bottom-90px overflow-hidden">
  <div class="container">
      <div class="row">
          <div class="col-lg-6">
              <div class="section-heading margin-bottom-40px">
                  <h2 class="sec__title">About Us</h2>
                  <h4 class="title font-size-16 line-height-26 pt-4 pb-2">
                    Greenline Company ltd is mainly a transport business that was incorporated in the 26th of March 2009</h4>
                  <p class="sec__desc font-size-16 pb-3">It mainly operates in Western Kenya regions with over 30 destinations. Greenline is the only company in it's area of operations that has both VIP and business class hence accommodating every individual.</p>
                  <p class="sec__desc font-size-16 pb-3">Our headquarters is located at Kitale town and our Nairobi main office is at OTC Temple road next to Old Jack N Jill supermarket. Greenline Company has expanded its areas of operations to Kampala Uganda. Kigali, Rwanda is our next stop, this will only apply for VIP buses. Good service is our motto!</p>
              </div><!-- end section-heading -->
          </div><!-- end col-lg-6 -->
          <div class="col-lg-5 ml-auto">
              <div class="image-box about-img-box">
                  <img src="assets/images/about/greenline-about.webp" alt="about-img" class="img__item img__item-1">
                  <img src="assets/images/about/greenline-about-2.webp" alt="about-img" class="img__item img__item-2">
              </div>
          </div><!-- end col-lg-5 -->
      </div><!-- end row -->
  </div><!-- end container -->
</section><!-- end about-area -->
<!-- ================================
  END ABOUT AREA
================================= -->

<!-- ================================
  STAR FUNFACT AREA
================================= -->
<section class="funfact-area padding-bottom-70px">
  <div class="container">
      <div class="row">
          <div class="col-lg-12">
              <div class="section-heading text-center">
                  <h2 class="sec__title">Our Numbers Say Everything</h2>
              </div><!-- end section-heading -->
          </div><!-- end col-lg-12 -->
      </div><!-- end row -->
      <div class="counter-box counter-box-2 margin-top-60px mb-0">
          <div class="row">
              <div class="col-lg-4 responsive-column">
                  <div class="counter-item counter-item-layout-2 d-flex">
                      <div class="counter-icon flex-shrink-0">
                          <i class="la la-building"></i>
                      </div>
                      <div class="counter-content">
                          <div>
                              <span class="counter" data-from="0" data-to="3"  data-refresh-interval="5">50</span>
                              <span class="count-symbol">+</span>
                          </div>
                          <p class="counter__title">Buses</p>
                      </div><!-- end counter-content -->
                  </div><!-- end counter-item -->
              </div><!-- end col-lg-4 -->
              <div class="col-lg-4 responsive-column">
                  <div class="counter-item counter-item-layout-2 d-flex">
                      <div class="counter-icon flex-shrink-0">
                          <i class="la la-globe"></i>
                      </div>
                      <div class="counter-content">
                          <div>
                              <span class="counter" data-from="0" data-to="400"  data-refresh-interval="5">100</span>
                              <span class="count-symbol">+</span>
                          </div>
                          <p class="counter__title">Destinations</p>
                      </div><!-- end counter-content -->
                  </div><!-- end counter-item -->
              </div><!-- end col-lg-4 -->
              <div class="col-lg-4 responsive-column">
                  <div class="counter-item counter-item-layout-2 d-flex">
                      <div class="counter-icon flex-shrink-0">
                          <i class="la la-check-circle"></i>
                      </div>
                      <div class="counter-content">
                          <div>
                              <span class="counter" data-from="0" data-to="40"  data-refresh-interval="5">40</span>
                              <span class="count-symbol">k+</span>
                          </div>
                          <p class="counter__title">Bookings</p>
                      </div><!-- end counter-content -->
                  </div><!-- end counter-item -->
              </div><!-- end col-lg-3 -->
          </div><!-- end row -->
      </div><!-- end counter-box -->
  </div><!-- end container -->
</section>
<!-- ================================
  END FUNFACT AREA
================================= -->

<app-footer></app-footer>

</div>
