import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../models/index'
import { UserService } from '../services/user.service'
@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent {
  isLoggedIn:any;
  user:User;
  api_error:Error;
  loadingStart:boolean = false;
  resMsg:any


  constructor( private users:UserService, private router:Router) {

  }

  logoutUser(){
    sessionStorage.setItem('isLoggedInEasy','false')
    sessionStorage.setItem('isLoggedInEasy','false');
    this.router.navigate(['/'])
  }

  ngOnInit() {

    this.isLoggedIn = sessionStorage.getItem('isLoggedInEasy');


    if(this.isLoggedIn ){
      this.user=new User().deserialize(JSON.parse(sessionStorage.getItem('userEasy')))
      this.getUserProfile(this.user);
    }else{
      this.router.navigate(['/'])
    }
  }


  getUserProfile(user:any){
    this.users.getProfile(user).subscribe(
      response => {
          this.loadingStart=false;
          this.resMsg=response;
          this.user=this.resMsg.data
          sessionStorage.setItem('userEasy',JSON.stringify(this.resMsg.data));
          sessionStorage.setItem('userEasy',JSON.stringify(this.resMsg.data));
      },
      error => {
        sessionStorage.removeItem('userEasy');
        // this.isLoggedIn=false;
      }
    );
  }

}
