
<router-outlet (activate)="onActivate($event)" >
  <app-spinner></app-spinner>
</router-outlet>
    <!-- end modal-shared -->
    <button id="openSuccessButton"  [class.is-hidden]="isHidden" data-toggle="modal" data-target="#signupSuccess">Open Modal</button>
    <button id="openModalReset"  [class.is-hidden]="isHidden" data-toggle="modal" data-target="#resetPassModal">Open Modal</button>
    <button id="openCodeButton"  [class.is-hidden]="isHidden" data-toggle="modal" data-target="#passCodeModal">Open Modal</button>
    <button id="openUpdatePassword"  [class.is-hidden]="isHidden" data-toggle="modal" data-target="#passwordUpdateModal">Open Modal</button>
    <button id="openResetSuccessButton"  [class.is-hidden]="isHidden" data-toggle="modal" data-target="#resetSuccess">Open Modal</button>
    <div class="modal-popup">
      <div
        class="modal fade"
        id="signupPopupForm"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <div>
                <h5 class="modal-title title" id="exampleModalLongTitle">
                  Sign Up
                </h5>
                <p class="font-size-14">Hello! Welcome Create a New Account</p>
              </div>
              <button
                type="button"
                class="close"
                id="closeSignup"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" class="la la-close"></span>
              </button>
            </div>
            <div class="modal-body">
              <div class="contact-form-action">
                <form id="ngFormSignup" novalidate [formGroup]="signupForm" (ngSubmit)="submitSignUp()">
                  <div class="input-box">
                    <label class="label-text">Firstname</label>
                    <div class="form-group">
                      <span class="la la-user form-icon"></span>
                      <input
                        class="form-control"
                        type="text"
                        name="first_name"
                        id="first_name"
                        formControlName="first_name"
                        placeholder="Type your Firstname"
                      />
                    </div>
                    <div  class="invalid-feedback-signup">
                      <div class="invalid-select" *ngIf="signupForm.get('first_name').hasError('required') && submittedsignupstatus">First name is required!</div>
                      <div class="invalid-select" *ngIf="signupForm.get('first_name').hasError('pattern') && submittedsignupstatus">Your firstname should not contain numbers or spaces</div>
                  </div>
                  <div *ngIf="!loadingSignup &&  user" class="invalid-feedback-signup">
                    <div class="invalid-select "  >{{user.getFname()}}</div>
                  </div>
                  </div>
                  <div class="input-box">
                    <label class="label-text">Lastname</label>
                    <div class="form-group">
                      <span class="la la-user form-icon"></span>
                      <input
                        class="form-control"
                        type="text"
                        name="last_name"
                        id="last_name"
                        formControlName="last_name"
                        placeholder="Type your lastname"
                      />
                    </div>
                    <div  class="invalid-feedback-signup">
                      <div class="invalid-select" *ngIf="signupForm.get('last_name').hasError('required') && submittedsignupstatus">Your lastname is required!</div>
                      <div class="invalid-select" *ngIf="signupForm.get('last_name').hasError('pattern') && submittedsignupstatus">Your lastname should not contain numbers or spaces</div>
                    </div>
                    <div *ngIf="!loadingSignup &&  user" class="invalid-feedback-signup">
                      <div class="invalid-select "  >{{user.getLname()}}</div>
                    </div>
                  </div>
                  <div class="input-box">
                    <label class="label-text">ID/Passport</label>
                    <div class="form-group">
                      <span class="la la-envelope form-icon"></span>
                      <input
                        class="form-control"
                        type="text"
                        name="id_number"
                        id="id_number"
                        formControlName="id_number"
                        placeholder="Type your ID/Passport"
                      />
                    </div>
                    <div class="invalid-feedback-signup">
                      <div class="invalid-select" *ngIf="signupForm.get('last_name').hasError('minlength')  && submittedsignupstatus">ID/PASSPORT should be between 7 to 14 characters</div>
                      <div class="invalid-select" *ngIf="signupForm.get('last_name').hasError('maxlength') && submittedsignupstatus">ID/PASSPORT should be between 7 to 14 characters</div>
                      <div class="invalid-select" *ngIf="signupForm.get('last_name').hasError('required')  && submittedsignupstatus">ID/PASSPORT is required!</div>
                      <div class="invalid-select" *ngIf="signupForm.get('last_name').hasError('pattern')  && submittedsignupstatus">ID/PASSPORT must be between 7 to 14 characters</div>
                  </div>
                    <div *ngIf="!loadingSignup &&  user" class="invalid-feedback-signup">
                      <div class="invalid-select "  >{{user.getIdno()}}</div>
                    </div>
                  </div>
                  <div class="input-box">
                    <label class="label-text">Phone number</label>
                    <div class="form-group">
                      <span class="la la-phone form-icon"></span>
                      <input
                        class="form-control"
                        type="text"
                        name="phone_number"
                        id="phone_number"
                        formControlName="phone_number"
                        placeholder="Type your phone number"
                      />
                    </div>
                    <div  class="invalid-feedback-signup">
                      <div class="invalid-select" *ngIf="signupForm.get('last_name').hasError('required')  && submittedsignupstatus">Your phone numberis required!</div>
                      <div class="invalid-select" *ngIf="signupForm.get('last_name').hasError('pattern')  && submittedsignupstatus">Your phone number should not contain numbers or spaces</div>
                  </div>
                  <div *ngIf="!loadingSignup &&  user" class="invalid-feedback-signup">
                    <div class="invalid-select "  >{{user.getPhone()}}</div>
                  </div>
                  </div>
                  <!-- end input-box -->
                  <div class="input-box">
                    <label class="label-text">Email Address</label>
                    <div class="form-group">
                      <span class="la la-envelope form-icon"></span>
                      <input
                        class="form-control"
                        placeholder="Email"
                        name="email"
                        id="email"
                        formControlName="email"
                        placeholder="Type your email"
                      />
                    </div>
                    <div  class="invalid-feedback-signup">
                      <div class="invalid-select" *ngIf="signupForm.get('last_name').hasError('required')  && submittedsignupstatus">Email is required!</div>
                      <div class="invalid-select" *ngIf="signupForm.get('last_name').hasError('email')  && submittedsignupstatus">Email must be a valid email address</div>
                  </div>
                  <div *ngIf="!loadingSignup &&  user" class="invalid-feedback-signup">
                    <div class="invalid-select "  >{{user.getEmail()}}</div>
                  </div>
                  </div>
                  <!-- end input-box -->
                  <div class="input-box">
                    <label class="label-text">Password</label>
                    <div class="form-group">
                      <span class="la la-lock form-icon"></span>
                      <input
                        class="form-control"
                        type="password"
                        name="password"
                        formControlName="password"
                        placeholder="Type password"
                      />
                    </div>

                    <div  class="invalid-feedback-signup">
                      <div class="invalid-select" *ngIf="signupForm.get('password').hasError('required')  && submittedsignupstatus">Password is required!</div>
                      <div class="invalid-select" *ngIf="signupForm.get('password').hasError('minlength')  && submittedsignupstatus">Your password should not be less than 6 characters</div>
                  </div>
                  </div>
                  <!-- end input-box -->
                 
                  <!-- end input-box -->
                  <div class="btn-box pt-3 pb-4">
                    <button type="submit" class="theme-btn w-100">
                      <img *ngIf="loadingSignup" src="assets/gifs/rll.gif">
                      <span *ngIf="!loadingSignup"> Register Account</span>
                    </button>
                  </div>
                  
                </form>
              </div>
              <!-- end contact-form-action -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end modal-popup -->

    <!-- end modal-shared -->
    <div class="modal-popup">
      <div
        class="modal fade"
        id="loginPopupForm"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <div>
                <h5 class="modal-title title" id="exampleModalLongTitle2">
                  Login
                </h5>
                <p class="font-size-14">Hello! Welcome to your account</p>
              </div>
              <button
                type="button"
                class="close"
                id="closeLogin"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" class="la la-close"></span>
              </button>
            </div>
            <div class="modal-body">
              <div class="contact-form-action">
                <form [formGroup]="loginForm" id="ngFormLogin" (ngSubmit)="submitLogin()">
                  <div class="input-box">
                    <label class="label-text">Email</label>
                    <div class="form-group">
                      <span class="la la-user form-icon"></span>
                      <input
                        class="form-control"
                        type="text"
                        name="email"
                        formControlName="email"
                        placeholder="Type your email"
                      />

                      <small class=" _ml-10 invalid-select" *ngIf="(loginForm.get('email').touched || loginsubmittedstatus) && loginForm.get('email').hasError('required') ">Please enter your email </small>
                      <small class=" _ml-10 invalid-select" *ngIf="(loginForm.get('email').touched || loginsubmittedstatus) && loginForm.get('email').hasError('email')">Should be a valid email.</small>
                    </div>
                  </div>
                  <!-- end input-box -->
                  <div class="input-box">
                    <label class="label-text">Password</label>
                    <div class="form-group mb-2">
                      <span class="la la-lock form-icon"></span>
                      <input
                        class="form-control"
                        type="password"
                        name="password"
                        formControlName="password"
                        placeholder="Type your password"
                      />
                      <small class=" _ml-10 invalid-select" *ngIf="(loginForm.get('password').touched || loginsubmittedstatus) && loginForm.get('password').hasError('required')">Please enter your password </small>
                      
                    </div>
                    <p *ngIf="!loadingLogin &&  is_api_error_login" >
                      <span class="invalid-select _ml-10"  >{{api_error_login}}</span>
                    </p>
                    <div
                      class="d-flex align-items-center justify-content-between"
                    >
                      <p class="forgot-password">
                        <a (click)="initiateResetPassword()" class="hover-custom">Can’t access your account?</a>
                      </p>
                    </div>
                  </div>
                  <!-- end input-box -->
                  <div class="btn-box pt-3 pb-4">
                    <button type="submit" class="theme-btn w-100">
                      <img *ngIf="loadingLogin" src="assets/gifs/rll.gif">
                      <span *ngIf="!loadingLogin">Login Account</span>
                    </button>
                  </div>
                </form>
              </div>
              <!-- end contact-form-action -->
            </div>

          </div>
        </div>
      </div>
    </div>
    <!-- end modal-popup -->
            <!-- Reset modal -->
            <div class="modal-popup">   
              <div
              class="modal fade"
              id="resetPassModal"
              tabindex="-1"
              role="dialog"
              aria-hidden="true"
            >
            <div class="modal-dialog modal-dialog-centered" role="document">
               <!-- Modal content-->
                <div class="modal-content">
                  <div class="modal-header">
                    <button id="closeReset" type="button" class="close" data-dismiss="modal">&times;</button>
                    <br>
                    <div class="modal-body">
                      <h4 class="theme-payment-page-signin-title review-header _ml-15">Recover your account  </h4>
                      <div class="facebook-login__disclaimer  _mb-20 _ml-15">
                        <p class="paragraphRegular">We can help you reset your password. First, enter your email and follow the instructions.</p>
                      </div>
                      <div *ngIf="!resetsubmitted &&  successReset" class="success-feedback">
                        <div class="success-select _ml-10"  >{{resetSuccessMsg}}</div>
                      </div>
                      <!-- <div class="or__splitter"><span>or</span></div> -->
                      <form [formGroup]="resetForm" id="resetForm" (ngSubmit)="submitResetPassword()" >
                        <div class="form-row  _mb-20 ">
                            <div class="col-sm-8 my-1">
                              <label class="sr-only" for="inlineFormInputEmailHome">Email</label>
                              <input type="text"
                              class="form-control"
                              id="inlineFormInputEmailHome"
                              placeholder="e.g your@email.com"
                              name="email"
                              formControlName="email"
                              >
                              <small class=" _ml-10 invalid-select" *ngIf="resetForm.get('email').touched && resetForm.get('email').hasError('required')">Please enter your email</small>
                              <small class=" _ml-10 invalid-select" *ngIf="resetForm.get('email').touched && resetForm.get('email').hasError('email')">Should be a valid email address</small>
                            </div>

                             <!-- end input-box -->
                          <div class="btn-box  pb-4">
                            <button type="submit" class="theme-btn w-100">
                              <img *ngIf="loadingReset" src="assets/gifs/rll.gif">
                              <span *ngIf="!loadingReset">SUBMIT</span>
                            </button>
                          </div>
                        </div>
                        </form>
          
                        <div  class="invalid-feedback">
                          <div class="invalid-select _ml-10"  >{{resetErrMsg}}</div>
                        </div>

          
                    </div>
          
                  </div>
          
                </div>
              </div>
            </div>
            </div>
      <!-- Modal -->
  <div class="modal-popup">   
  <div id="signupSuccess" class="modal fade" role="dialog">
    <div class="modal-dialog  modal-dialog-centered">
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-header">
          <button id="closeSuccess" type="button" class="close" data-dismiss="modal">&times;</button>
          <br>
          <div class="modal-body">
            <h4 class="theme-payment-page-signin-title review-header _ml-10">Success</h4>

            <div class="facebook-login__disclaimer  _mb-20 _ml-10">

              <p class="paragraphRegular">Thank you for registering with Buupass, kindly go to your email and click the confirmation link. Thank you .</p>
            </div>
          </div>

        </div>

      </div>
    </div>
    </div>
  </div>

  <!-- modal update password -->
  <div class="modal-popup">
  <div id="passwordUpdateModal" class="modal fade" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-header">
          <button id="closePasswordUpdate" type="button" class="close" data-dismiss="modal">&times;</button>
          <br>
          <div class="modal-body">
            <h4 class="theme-payment-page-signin-title review-header _ml-15">    Reset your password       </h4>
            <div class="facebook-login__disclaimer  _mb-20 _ml-15">
              <p class="paragraphRegular">8-character minimum; case sensitive.</p>
            </div>
            <!-- <div class="or__splitter"><span>or</span></div> -->
            <form [formGroup]="passwordUpdateForm" id="passwordUpdateForm" (ngSubmit)="updatePassword()" >
              <div class="form-row  _mb-20 ">
                  <div class="col-sm-6 my-1">
                    <label class="sr-only" for="inlineFormInputPassword">Password</label>
                    <input type="password"
                    class="form-control"
                    id="inlineFormInputPassword"
                    placeholder="Password"
                    name="password"
                    formControlName="password"
                    >
                  </div>
                  <div class="col-sm-6 my-1">
                    <label class="sr-only" for="inlineFormInputConfirmPassword">Re-enter Password</label>
                    <input type="password"
                    class="form-control"
                    id="inlineFormInputConfirmPassword"
                    placeholder="Re-enter Password"
                    name="confirmpassword"
                    formControlName="confirmpassword"
                    >
                    <small class=" _ml-10 invalid-select" *ngIf="passwordUpdateForm.get('password').touched && passwordUpdateForm.get('password').hasError('required')">Please enter password</small>
                    <small class=" _ml-10 invalid-select" *ngIf="passwordUpdateForm.get('password').touched && passwordUpdateForm.get('password').hasError('minlength')">Should be 8-character minimum.</small>
                    <small class=" _ml-10 invalid-select" *ngIf="passwordUpdateForm.get('confirmpassword').touched && passwordUpdateForm.get('confirmpassword').hasError('confirmedValidator') ">Password and Confirm Password must be match.</small>
    
                  </div>
                </div>
               
                <div class="form-row  _mb-20 ">
                  <div class="col-sm-6 my-1 _mt-20">
                    <button   form="passwordUpdateForm" class="theme-search-area-submit theme-search-area-submit-curved theme-search-area-submit-glow">
                    <img *ngIf="loadingPasswordUpdate" src="assets/gifs/rll.gif">
                    <span *ngIf="!loadingPasswordUpdate">SUBMIT</span>
                    </button>
                  </div>
              
              </div>
              
              </form>

              <div  class="invalid-feedback">
                <div class="invalid-select _ml-10"  >{{codeSuccessMsg}}</div>
              </div>

          
            </div>

        </div>

      </div>
    </div>
    </div>
  </div>


      <!-- Modal -->
      <!-- reset code modal -->
      <div class="modal-popup">   
        <div id="passCodeModal" class="modal fade" role="dialog">
          <div class="modal-dialog modal-dialog-centered">
            <!-- Modal content-->
            <div class="modal-content">
              <div class="modal-header">
                <button id="closeCode" type="button" class="close" data-dismiss="modal">&times;</button>
                <br>
                <div class="modal-body">
                  <h4 class="theme-payment-page-signin-title review-header _ml-15">Verify your identity</h4>
                  <div class="facebook-login__disclaimer  _mb-20 _ml-15">
                    <p class="paragraphRegular">We just sent a code to {{user_email_reset}}. Check your email for a message from Buupass, and enter the code here.</p>
                  </div>
                  <!-- <div class="or__splitter"><span>or</span></div> -->
                  <form [formGroup]="codeForm" id="codeForm" (ngSubmit)="submitCode()" >
                    <div class="form-row  _mb-20 ">
                        <div class="col-sm-6 my-1">
                          <label class="sr-only" for="inlineFormInputEmailHomeForm">Enter Code</label>
                          <input type="text"
                          class="form-control"
                          id="inlineFormInputEmailHomeForm"
                          placeholder="Enter code"
                          name="code"
                          formControlName="code"
                          >
                          <small class=" _ml-10 invalid-select" *ngIf="codeForm.get('code').touched && codeForm.get('code').hasError('required')">Please enter a code </small>

                        </div>

                        <div class="btn-box  pb-4">
                          <button type="submit" class="theme-btn heme-search-area-submit-curved theme-search-area-submit-glow w-100">
                            <img *ngIf="loadingCode" src="assets/gifs/rll.gif">
                            <span *ngIf="!loadingCode">SUBMIT</span>
                          </button>
                        </div>
                    </div>
                    </form>
      
                    <div *ngIf="!codeubmitted " class="invalid-feedback">
                      <div class="invalid-select _ml-10"  >{{updatePassSuccessMsg}}</div>
                    </div>
                </div>
      
              </div>
      
            </div>
          </div>
          </div>
        </div>

        <!-- reset success modal -->
        <div id="resetSuccess" class="modal fade" role="dialog">
          <div class="modal-dialog">
            <!-- Modal content-->
            <div class="modal-content">
              <div class="modal-header">
                <button id="closeResetSuccess" type="button" class="close" data-dismiss="modal">&times;</button>
                <br>
                <div class="modal-body">
                  <h4 class="theme-payment-page-signin-title review-header _ml-10">Success</h4>
      
                  <div class="facebook-login__disclaimer  _mb-20 _ml-10">
      
                    <p class="paragraphRegular">Your password has been updated successfully. Thank you .</p>
                  </div>
                </div>
      
              </div>
      
            </div>
          </div>
          </div>


        