
    <div class="theme-footer" id="mainFooter">
        <div class="container _ph-mob-0">
          <div class="row row-eq-height row-mob-full" data-gutter="60">

            <div class="col-md-3 col-lg-3 col-sm-12 mobile-hidden">
              <div class="theme-footer-section theme-footer-">
                <a class="theme-footer-brand _mt-20" href="#">
                  <img src="assets/img/company1/logo.webp" alt="http://greenlinesafaris.com/" title="Image Title"/>
                </a>
              </div>
            </div>

            <div class="col-md-3 col-lg-3 col-sm-12">
              <div class="theme-footer-section theme-footer-">
                <h5 class="theme-footer-section-title">Our Contacts</h5>
                <ul class="theme-footer-section-list">
                  <li>
                    <h6 class="phone-titles" style="margin-top:0px;"> Nairobi:</h6>
                    <span class="phones-wrapper"><a class="contacts-links" href="tel:+254798546817">+254798546817/+254719308288</a> </span>
                  </li>
                  
                  <li>
                    <h6 class="phone-titles"> Email:</h6>
                    <span class="phones-wrapper"><a href="mailto:greenlinecompany22@gmail.com">greenlinecompany22@gmail.com</a></span>
                  </li>

                </ul>
              </div>

            </div >

            <div class="col-md-3 col-lg-3 col-sm-12 mobile-hidden">
              <div class="theme-footer-section theme-footer-">
                <h5 class="theme-footer-section-title">Top Routes</h5>
                <ul class="theme-footer-section-list">
                  <li class="hover-custom">
                    <a (click)="clickNairobiKTL()" >Nairobi - Kitale</a>
                  </li>
                  <li class="hover-custom">
                    <a (click)="clickNairobiMl()" >Nairobi - Malaba</a>
                  </li>
                  <li class="hover-custom">
                    <a (click)="clickNairobiLk()" >Nairobi - Lwakhakha </a>
                  </li>
                  <li class="hover-custom">
                    <a (click)="clickNairobiMb()" >Nairobi - Mbale </a>
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-md-3 col-lg-3 col-sm-12 mobile-app-cta">
              <div class="_mt-40 mobile-app-cta-inner text-center">
                  <h3 class="_ml-10 ">GET THE APP!</h3>
                  <a class="centered-img"  target="_blank" href="https://play.google.com/store/apps/details?id=com.greenlinesafaris&hl=en_ZA&gl=US&pli=1">
                    <img src="assets/img/company1/app-logo-GooglePlay.png" alt="Download on play store" class="icon-app-store">
                </a>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="theme-copyright">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <p class="theme-copyright-text">Copyright &copy; 2021
                <a href="https://buupass.com/">BuuPass Kenya Limited</a>. All rights reserved.
              </p>
            </div>
            <div class="col-md-6">
              <ul class="theme-copyright-social">
                <li>
                  <a class="fa fa-facebook"  href="https://www.facebook.com/profile.php?id=100057376512538" target="_blank"></a>
                </li>

                <li>
                  <a class="fa fa-twitter" href="https://twitter.com/Greenline_bus" target="_blank"></a>
                </li>

                <li>
                  <a class="fa fa-instagram" href="https://www.instagram.com/greenlinesafaris/" target="_blank"></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
