import { Component } from '@angular/core';
import {  Router } from '@angular/router';
import { User } from '../models/index'
import { UserService } from '../services/user.service'
@Component({
  selector: 'app-my-booking',
  templateUrl: './my-booking.component.html',
  styleUrls: ['./my-booking.component.css']
})
export class MyBookingComponent {

  isLoggedIn:any;
  user:User;
  api_error:Error;
  loadingStart:boolean = false;
  resMsg:any
  trips:any
  isTripAvailable:boolean=false

  constructor( private users:UserService, private router: Router) {

  }

  logoutUser(){
    sessionStorage.setItem('isLoggedInEasy','false')
    sessionStorage.setItem('isLoggedInEasy','false');
    this.router.navigate(['/'])

  }

  ngOnInit() {

    this.isLoggedIn = sessionStorage.getItem('isLoggedInEasy');

    if(this.isLoggedIn ){
      this.user=new User().deserialize(JSON.parse(sessionStorage.getItem('userEasy')))
      this.getUserProfile(this.user);
    }else{
      this.router.navigate(['/'])
    }
  }


  getUserProfile(user:any){
    this.users.getProfile(user).subscribe(
      response => {
          this.loadingStart=false;
          this.resMsg=response;
          sessionStorage.setItem('userEasy',JSON.stringify(this.resMsg.data));
          sessionStorage.setItem('userEasy',JSON.stringify(this.resMsg.data));
          this.getUserTrips( this.user.phone_number)
      },
      error => {
        sessionStorage.removeItem('userEasy');
        // this.isLoggedIn=false;
      }
    );
  }

  getUserTrips(phone:string){
    this.users.getTrips(phone).subscribe(
      response => {

          this.loadingStart=false;
          this.resMsg=response;
          this.trips=this.resMsg;
          if(this.trips.length > 0){
            this.isTripAvailable=true;
          }

      },
      error => {


      }
    );
  }

}
